@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

:root {
  --hue-solid: #f2f3f5;
  --background-color: #16171a;
  --navbar-background-color: #232429cc;
  --main-color: #00d4a1;
  --secondary-color-gradient-low: #906cfb;
  --secondary-color-gradient-high: #524ae6;
  --primary-color: #16171a;
  --layout-body-background: #f2f3f5;
  --border-radius-base: 10px;
  --border-color-base: transparent;
  --font-size-base: 15px;
  --menu-icon-size: 24px;
  --menu-item-color: #888d9b;
  --menu-highlight-color: #16171a;
  --menu-item-active-border-width: 0px;
  --menu-item-active-bg: transparent;
  --height-base: 40px;
  --border-width-base: 0px;
  --outline-width: 0px;
  --select-item-selected-font-weight: 500;
  --select-item-selected-bg: transparent;
  --select-dropdown-height: 40px;
  --dropdown-line-height: 30px;
  --menu-icon-margin-right: 12px;
  --text-weight-base: 500;
  --main-text-color: #16171a;
  --secondary-text-color: #848999;
  --third-text-color: #dfe0e6;
  --card-border-radius: 14px;
  --card-padding: 8px;
  --desktop-page-padding: 48px;
  --mobile-page-padding: 16px;
  --negative-mobile-page-padding: calc(-1 * 16px);
  --tooltip-bg: #16171a;
  --segment-border-radius: 6px;
  --segment-item-height: 32px;
  --header-items-top-offset: 12px;
  --layout-header-height: 64px;
}

body {
  background-color: #f2f3f5;
  margin: 0;
  font-family:
    "inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--text-weight-base) !important;
}

#root {
  min-height: 100vh;
  height: 100%;
}

span {
  font-weight: var(--text-weight-base);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(132 132 132) rgb(241 241 241);
  scrollbar-gutter: auto;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

/* Removing focus-visible that starting appearing in the recharts tooltip box */
*:focus-visible {
  outline: none;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(179 179 179);
  border-radius: 20px;
  border: 2px solid rgb(241 241 241);
}

/* Override some antd styled */
.ant-select-small .ant-select-item {
  line-height: 18px;
  min-height: 28px;
}
.ant-select-small .ant-select-item .ant-select-item-option-content {
  line-height: 18px;
}
.ant-select-small .ant-select-item .ant-select-item-option-state {
  line-height: 18px;
}
.ant-select-item {
  font-weight: var(--text-weight-base);
}

.ant-input {
  font-weight: var(--text-weight-base);
}

.rc-virtual-list-holder-inner > .ant-select-item-option {
  border-radius: var(--border-radius-base);
}

.rc-virtual-list-scrollbar {
  width: 6px !important;
}

.rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
  background-color: rgb(179, 179, 179) !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background: white;
  font-weight: 500;
}

.ant-table-wrapper .ant-table-thead > tr > th::before {
  display: none;
}

.ant-table-wrapper .ant-table-thead > tr > th .ant-space-item {
  font-size: 13px;
}

.ant-table-content table tr th {
  border-bottom: 1px solid var(--hue-solid);
  padding-top: 0px;
  padding-bottom: var(--table-header-padding);
  font-size: 13px;
}

.ant-table-content table tr th:first-child {
  padding-left: 2px;
}

.ant-table-content table tr td {
  border-bottom: 1px solid var(--hue-solid);
}
.ant-table-content table tr td:first-child {
  padding-left: 2px;
}
.ant-table-content table tr td:last-child {
  padding-right: 0px;
}
.ant-table-content table tr:last-child td {
  border-bottom: none;
}

.ant-menu-item span.ant-menu-title-content:hover {
  text-decoration: underline;
}

button > span.ant-btn-loading-icon {
  display: none !important;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-inline-start: 0;
}

div {
  box-sizing: border-box;
}
/* Override some antd styled Ends */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
